'use client'
import React, { useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import PaymentSuccessContent from './PaymentSuccessContent'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/app/globalRedux/store'
import { useRouter } from 'next/navigation'
import {
  setOpen,
  setShowSuccessModal,
  setStage,
} from '@/app/globalRedux/signUp/signUp'
const PaymentModal = () => {
  const dispatch = useDispatch()
  const signUp = useSelector((state: RootState) => state.signUp)
  const router = useRouter()
  useEffect(() => {
    if (signUp.showSuccessModal) {
      setTimeout(() => {
        dispatch(setShowSuccessModal(false))
        dispatch(setStage(3))
        dispatch(setOpen(true))

        router.push(`?page=validate-mail&email=${signUp.email}`)
      }, 5000)
    }
  })

  return (
    <div>
      <Dialog
        open={signUp.showSuccessModal}
        className=" "
        sx={{
          '& .MuiDialog-paper': {
            maxHeight: 'unset!important ',
            maxWidth: 'unset !important',
            height: '70dvh',
            width: '70dvw',
            alignItems: 'center',
          },
        }}
      >
        <DialogContent className="border-0  w-[1000px]  items-center justify-center p-5 mobile-width mobile:p-3">
          <PaymentSuccessContent />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PaymentModal
