'use client'
import { getDomain } from '@/lib/utils'
import { removeLanguageCode } from '@/middleware'
import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'

const domainMaps: Record<string, Record<string, string>> = {
  prod: {
    'pr-es-fructidor.app.ubq.network': 'es',
    'pr-it-fructidor.app.ubq.network': 'it',
    'pr-fr-fructidor.app.ubq.network': 'fr',
    'pr-en-fructidor.app.ubq.network': 'en',
  },
  stage: {
    'st-fr-fructidor.app.ubq.network': 'fr',
    'st-it-fructidor.app.ubq.network': 'it',
    'st-es-fructidor.app.ubq.network': 'es',
  },
}

const getRedirectUrl = (domain: string, path: string, lng: string): string => {
  const domainType = Object.entries(domainMaps).find(
    ([, domains]) => domain in domains
  )
  if (!domainType) return `/${lng}/${path}`

  const [, domains] = domainType
  const baseDomain = Object.keys(domains).find((key) => domains[key] === lng)

  return baseDomain
    ? `https://${baseDomain}/${lng}/${removeLanguageCode(path)}`
    : `/${lng}/${path}`
}

export const LanguageBase = ({
  lng,
  path = '',
}: {
  lng: string
  path?: string
}) => {
  const [domain, setDomain] = useState('')
  const [lang, setLang] = useState<string>(lng)
  const languageDropdownRef = useRef<HTMLUListElement>(null)
  const [showDropDown, setShowDropDown] = useState<boolean>(false)

  useEffect(() => {
    setLang(lng)
    setShowDropDown(false)
  }, [lng])

  useEffect(() => {
    setDomain(window.location.host)
    const handleClickOutside = (event: MouseEvent) => {
      if (
        languageDropdownRef.current &&
        !languageDropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropDown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const languages = [
    {
      path: 'en',
      icon: '/assets/Icons/flags/americanFlag.png',
      language: 'English',
    },
    {
      path: 'it',
      icon: '/assets/Icons/flags/italyFlag.png',
      language: 'Italian',
    },
    {
      path: 'fr',
      icon: '/assets/Icons/flags/franceFlag.png',
      language: 'French',
    },
    {
      path: 'es',
      icon: '/assets/Icons/flags/spainFlag.png',
      language: 'Spanish',
    },
  ]

  const toggleDropDown = () => {
    setShowDropDown((prevState) => !prevState)
  }

  const getLang = (lang: string) => {
    const language = languages.find((element) => element.path === lang)
    return language ? language.language : ''
  }

  return (
    <div className="relative select-none pointer-cursor px-3 mobile:px-0">
      <div
        className="flex w-max items-center gap-2 text-sm cursor-pointer"
        onClick={toggleDropDown}
      >
        {languages.map((l) => {
          if (l.path === lang) {
            return (
              <Image
                loading="lazy"
                key={l.path}
                width={20}
                height={10}
                src={l.icon}
                alt={l.language}
              />
            )
          }
          return null
        })}
        <span className="text-sm language-text">{getLang(lang)}</span>
        <span>
          <Image
            loading="lazy"
            width={7}
            height={7}
            src="/assets/Icons/DownArrow.svg"
            alt=""
            className={`arrowIcon transition ${
              showDropDown ? 'rotate-180' : ''
            }`}
          />
        </span>
      </div>

      {showDropDown && (
        <>
          <div
            className="flex absolute right-0 down-0"
            style={{
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderBottom: '10px solid white',
              width: '0',
              height: '0',
              margin: '5px',
            }}
          ></div>

          <ul
            className="absolute shadow-lg flex flex-col bg-[white] border-white rounded-[2px] my-3 z-10 w-full"
            ref={languageDropdownRef}
          >
            {languages
              .filter((e) => e.language !== getLang(lang))
              .map((l, index) => (
                <li
                  className="text-sm flex items-center gap-2 relative w-20 py-1 mx-auto mobile:mx-[5px]"
                  key={`${l.path} ${index}`}
                >
                  <a
                    className=" flex gap-[10px]"
                    href={getRedirectUrl(domain, path, l.path)}
                    onClick={() => {
                      document.cookie = `lang=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${getDomain()};secure`
                    }}
                  >
                    <Image
                      loading="lazy"
                      width={18}
                      height={10}
                      src={l.icon}
                      alt={l.language}
                    />
                    <span className="text-sm cursor-pointer">
                      <p>{l.language}</p>
                    </span>
                  </a>
                </li>
              ))}
          </ul>
        </>
      )}
    </div>
  )
}
