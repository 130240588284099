import { LegendItem } from './AreaChartTypes'
import { StatisticsAlertResponse } from './BuyerAlertStatistics'

export interface AverageTraffic {
  month: string
  year: string
  viewers: number
}

export interface StatisticsCountry {
  country: string
  count: number
}

export class Statistics {
  status: string = 'loading'
  static pages = [
    '/statistics/dashboard',
    '/statistics/sales_offers',
    '/statistics/buyers_alert',
    // '/statistics/news',
    // '/statistics/ad_banners',
  ]
  static formatLegend = (statistics: StatisticsAlertResponse): LegendItem[] => {
    const totalBuyerAlertCount = statistics?.total_buyer_alert_for_company
    const averageViews = statistics?.average_view_traffic?.reduce(
      (sum, item) => sum + item?.viewers + sum,
      0
    )
    const averageTimeSpent =
      statistics?.most_visited?.reduce(
        (sum, item) => sum + item?.alert?.avg_time,
        0
      ) / statistics.most_visited.length

    const averageClick =
      statistics?.most_interacted?.reduce(
        (sum, item) => sum + item?.alert?.clicked,
        0
      ) / statistics?.most_interacted?.length

    return [
      {
        name: 'Total Buyers Alert Listed',
        count: totalBuyerAlertCount,
        suffix: '',
      },
      { name: 'Average Views', count: averageViews, suffix: '' },
      {
        name: 'Average Time Spent',
        ...Statistics.formatAverageTime(averageTimeSpent),
      },
      { name: 'Average no. of Clicks', count: averageClick, suffix: '' },
      { name: 'Average no. of Messages', count: 0, suffix: '' },
    ]
  }

  static formatAverageTime(averageTimeSpent: number) {
    if (averageTimeSpent > 60) {
      return { count: averageTimeSpent / 60, suffix: '(min)' }
    } else {
      return { count: averageTimeSpent, suffix: '(sec)' }
    }
  }

  static formatGeoMapData(countryData: StatisticsCountry[]) {
    return countryData.map((country) => [country?.country, country?.count])
  }

  static formatLineChart(averageTraffic: AverageTraffic[]) {
    const months = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    averageTraffic.forEach((traffic) => {
      months[Number(traffic.month) - 1] = traffic.viewers
    })
    return months
  }
}