'use client'
import { setCompanyFiltersToInitialState } from '@/app/globalRedux/filter/companyFilter'
import { clearFilters } from '@/app/globalRedux/filter/newsFilter'
import { setProductFiltersToInitialState } from '@/app/globalRedux/filter/productFilter'
import { RootState } from '@/app/globalRedux/store'
import useLoginRouter from '@/app/hooks/useLoginRouter'
import useLogout from '@/app/hooks/useLogout'
import { useTranslation } from '@/app/i18n/client'
import { LoginResponse } from '@/app/types/loginResponseType'
import { languageTransition } from '@/app/utils/language'
import { Button } from '@/components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { Separator } from '@/components/ui/separator'
import { companyProfileRedirection } from '@/lib/redirectionUtils'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Image from 'next/image'
import Link from 'next/link'
import { useParams, usePathname } from 'next/navigation'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const UserProfile = ({
  isMobile,
  user,
}: {
  isMobile: boolean
  user: LoginResponse
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigateToLogin = useLoginRouter()
  const pathname = usePathname()
  const userName = useSelector((state: RootState) => state.user.userName)
  const role = useSelector((state: RootState) => state.user.userRole)
  const { friendly_url, companyIndustry } = useSelector(
    (state: RootState) => state.user
  )

  const featureFlag = useSelector(
    (state: RootState) => state.featureFlag.feature_flags
  )
  const [popoverOpen, setPopoverOpen] = useState(false)
  const params = useParams()
  const logout = useLogout()

  const filterInitialState = () => {
    dispatch(clearFilters())
    dispatch(setCompanyFiltersToInitialState())
    dispatch(setProductFiltersToInitialState())
  }

  const userLoggedIn = () => {
    const { first_name, last_name, company, email } = user?.user || {}
    return first_name || last_name || company?.name || email
  }

  let name: string = ''
  name =
    !user?.user?.first_name && !user?.user?.last_name
      ? user?.user?.company?.name ?? ''
      : `${user?.user?.first_name ?? ''} ${user?.user?.last_name ?? ''}`
  if (!name || name.length <= 0) {
    name = user?.user?.email?.split('@')[0]
  }

  useEffect(() => {
    if (languageTransition.find((e) => e.path == pathname.split('/').pop())) {
      filterInitialState()
    }
  }, [pathname])

  const isAdminOrEditorOrManager = [
    'fructidor_admin',
    'fructidor_editor',
    'fructidor_manager',
    'chief_of_staff',
  ].includes(role)
  return (
    <div>
      {userLoggedIn() ? (
        <div className="flex w-max">
          <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
            <PopoverTrigger>
              <div className="w-full flex items-center relative">
                <div className="bg-[white] h-9 w-9 flex items-center justify-center rounded-full">
                  <p className="mx-2 mt-0.5">
                    {name?.charAt(0)?.toUpperCase() ?? ''}
                    {name?.split(' ')[1]?.charAt(0)?.toUpperCase() ?? ''}
                  </p>
                </div>
                <div className="flex items-center mobile:hidden">
                  <span className="flex flex-col ml-1">
                    <span className="block whitespace-nowrap text-sm font-ns_bold w-fit max-w-[85px] overflow-hidden text-ellipsis">
                      {name}
                    </span>
                    <span className="block whitespace-nowrap text-[10px] w-fit max-w-[85px] overflow-hidden text-ellipsis">
                      {user?.user?.company?.name ?? ''}
                    </span>
                  </span>
                  <KeyboardArrowDownIcon />
                </div>
              </div>
            </PopoverTrigger>
            <PopoverContent className="after:absolute absolute -right-20 mobile:-right-4 block border-b-white border-solid border-transparent w-max p-0  rounded-[3px] mt-[15px] ">
              <span className="flex absolute right-[19px] mobile:right-0 -top-[15.6px] w-0 h-0 m-[5px] border-l-[10px] border-r-[10px] border-b-[10px] border-b-white border-l-transparent border-r-transparent"></span>
              {isAdminOrEditorOrManager ? (
                <Link
                  href={
                    process.env.NODE_ENV === 'development'
                      ? 'http://localhost:3000/fructidor_admin'
                      : '/fructidor_admin'
                  }
                >
                  <div
                    className="flex gap-3 items-center cursor-pointer py-3 px-2 mx-2"
                    onClick={() => {
                      setPopoverOpen(false)
                    }}
                  >
                    <div className="bg-gray-300 p-2 h-4 w-4 text-[10px] rounded-[50%] flex items-center justify-center font-bold">
                      <p className="mx-2 mt-0.5">
                        {userName?.charAt(0)?.toUpperCase() ?? ''}
                      </p>
                    </div>
                    {t('Manage Fructidor')}
                  </div>
                </Link>
              ) : (
                <Link href={`/${params?.lng}/myfructidor/edit_profile`}>
                  <div
                    className="flex gap-3 items-center cursor-pointer py-3 px-2 mx-2"
                    onClick={() => {
                      setPopoverOpen(false)
                    }}
                  >
                    <div className="bg-gray-300 p-2 h-4 w-4 text-[10px] rounded-[50%] flex items-center justify-center font-bold">
                      <p className="mx-2 mt-0.5">
                        {userName?.charAt(0)?.toUpperCase() ?? ''}
                      </p>
                    </div>
                    {t('My Fructidor')}
                  </div>
                </Link>
              )}
              <Separator />
              {/* Feature Flag */}

              {/* User detail and Login Model */}
              <Link href={`/${params?.lng}/member/profile`}>
                <div
                  className="flex gap-3 items-center cursor-pointer py-3 px-2 mx-2"
                  onClick={() => {
                    setPopoverOpen(false)
                  }}
                >
                  <Image
                    loading="lazy"
                    width={16}
                    height={16}
                    src="/assets/Icons/user.svg"
                    alt="settings-icon"
                    className="w-4 h-4"
                  />
                  {t('My User Profile')}
                </div>
              </Link>
              <Separator />
              {!isAdminOrEditorOrManager && (
                <div>
                  <Link
                    href={companyProfileRedirection(
                      companyIndustry,
                      friendly_url,
                      String(params?.lng) ?? 'en'
                    )}
                  >
                    <div
                      className="flex items-center cursor-pointer py-3 px-2 gap-3 mx-2"
                      onClick={() => {
                        setPopoverOpen(false)
                      }}
                    >
                      <Image
                        loading="lazy"
                        width={16}
                        height={16}
                        src="/assets/Icons/miniWebsite.svg"
                        alt="settings-icon"
                      />
                      {t('Mini Website')}
                    </div>
                  </Link>
                  <Separator />
                </div>
              )}

              {!isAdminOrEditorOrManager &&
                featureFlag.find((item) => item.name == 'statistics')?.flag && (
                  <React.Fragment>
                    <Link href={`/${params?.lng}/statistics/dashboard`}>
                      <div
                        className="flex gap-3 items-center cursor-pointer py-3 px-2 mx-2"
                        onClick={() => {
                          setPopoverOpen(false)
                        }}
                      >
                        <Image
                          loading="lazy"
                          width={16}
                          height={16}
                          src="/assets/Icons/statisticsIcon.svg"
                          alt="settings-icon"
                          className="w-4 h-4"
                        />
                        {t('Statistics')}
                      </div>
                    </Link>
                    <Separator />
                  </React.Fragment>
                )}

              <div
                className="flex gap-3 items-center cursor-pointer py-3 px-2 mx-2"
                onClick={() => {
                  logout()
                  setPopoverOpen(false)
                }}
              >
                <Image
                  loading="lazy"
                  width={16}
                  height={16}
                  src="/assets/Icons/Leave.svg"
                  alt="settings-icon"
                />
                {t('Sign Out')}
              </div>
            </PopoverContent>
          </Popover>
        </div>
      ) : isMobile ? (
        <Image
          loading="lazy"
          width={20}
          height={20}
          src="/assets/Icons/contactLogo.svg"
          alt="contactLogo"
          onClick={() => {
            navigateToLogin('login')
          }}
        />
      ) : (
        <div className="flex w-max ">
          {!userLoggedIn() && (
            <Button
              className={`h-[44px] w-fit py-2  font-ns_bold text-[14px] ${pathname.includes('/en') || pathname.includes('/it') ? 'px-8' : 'px-4'}`}
              variant="outline"
              size="rounded"
              onClick={() => {
                navigateToLogin('login')
              }}
            >
              {t('Login')}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default UserProfile
