'use client'
import {
  setOpen,
  setShowEmailVerification,
} from '@/app/globalRedux/signUp/signUp'
import { RootState } from '@/app/globalRedux/store'
import { useTranslation } from '@/app/i18n/client'
import { useRouter } from 'next/navigation'
import { useDispatch, useSelector } from 'react-redux'
import Image from 'next/image'

const PaymentSuccessContent = () => {
  const router = useRouter()
  const email = useSelector((state: RootState) => state.signUp.email)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigateVerificationMail = () => {
    dispatch(setOpen(true))
    dispatch(setShowEmailVerification(true))
    router.push(`?page=validate-mail&tab=company&email=${email}`)
  }
  return (
    <div className=" flex  items-center justify-center  flex-col">
      <div className="text-primary font-ns_bold ">
        {t('Transaction Status')}
      </div>
      <div className="text-primary-foreground text-[30px] font-ns_bold">
        {t('Payment Complete')}
      </div>
      <Image
        loading="lazy"
        src={'/assets/Icons/PaymentSuccessful.svg'}
        width={500}
        height={500}
        alt="alt-text"
        className=" !h-[30dvh] mobile:w-[300px] mobile:h-[auto]"
      />
      <div className=" text-[#000000] text-[30px] font-ns_bold mt-4">
        {t('Awesome')}
      </div>
      <div className="text-black">{"Youre'in"}</div>
      <div className=" mt-10 text-sm mobile:mt-5 mobile:text-center">
        {t('Redirect to Email Verification')}
        <span
          className=" underline text-primary ml-1 cursor-pointer "
          onClick={() => {
            navigateVerificationMail()
          }}
        >
          {t('Click Here')}
        </span>
      </div>
    </div>
  )
}

export default PaymentSuccessContent
