'use client'

import { toast } from 'sonner'

export const login = async (
  email: string,
  password: string,
  rememeberMe: boolean
) => {
  const data = {
    api_v1_user: {
      email: email,
      password: password,
      remember_me: rememeberMe,
    },
  }
  try {
    const response = await fetch(`${process.env.API_URL}/users/sign_in`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'include',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    })
    const responseData = await response.json()

    return responseData
  } catch (error: any) {
    console.error('Error during login:', error)
    toast.error(error.message)
    return error
  }
}

export const setNewPasword = async (
  token: string,
  password: string,
  newPassword: string
) => {
  try {
    const data = {
      api_v1_user: {
        reset_password_token: token,
        password: password,
        password_confirmation: newPassword,
      },
    }

    const response = await fetch(
      `${process.env.API_URL}/users/reset_password`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    )
    const responseData = await response.json()
    return responseData
  } catch (error) {
    console.log('Error in Setting New Password', error)
  }
}

export const setResetPasswordEmail = async (email: string) => {
  try {
    const data = {
      api_v1_user: {
        email: email,
      },
    }
    const response = await fetch(
      `${process.env.API_URL}/users/reset_password`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    )
    const responseData = await response.json()
    return responseData
  } catch (error) {
    console.log('Error in Sending Email to the User', error)
  }
}

export const logOutUser = async () => {
  try {
    const response = await fetch(`${process.env.API_URL}/users/sign_out`, {
      method: 'DELETE',
      credentials: 'include',
    })

    return response
  } catch (error) {
    console.log('Error in Logging out', error)
  }
}

export const validateEmail = async (token: string) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/users/confirmation?confirmation_token=${token}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          credentials: 'include',
        },
      }
    )
    const responseData = await response.json()
    return responseData
  } catch (error) {
    console.log('Error in Validation' + error)
  }
}

export const isValidResetPasswordToken = async (token: string) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/validate_reset_token?token=${token}`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          credentials: 'include',
        },
      }
    )
    const responseData = response.json()
    return responseData
  } catch (error) {
    console.log('Error in Validation' + error)
  }
}
