import { RootState } from '@/app/globalRedux/store'
import useNavItems from '@/app/hooks/useNavItems'
import useScreenSize from '@/app/hooks/useScreenSize'
import { useTranslation } from '@/app/i18n/client'
import { LoginResponse } from '@/app/types/loginResponseType'
import Image from 'next/image'
import Link from 'next/link'
import { useParams, usePathname } from 'next/navigation'
import { Suspense, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import HeaderSearchBar from '../../(home)/search/HeaderSearchBar'
import { LanguageDropDown } from '../Language'
import UserProfile from './UserProfile'

const items = [
  'buyersAlert',
  'salesOffers',
  'news',
  'register',
  'solutions',
  'aboutUs',
  'advertise',
]

const routeMap: Record<string, string> = {
  buyers_alert: 'buyersAlert',
  sales_offers: 'salesOffers',
  news: 'news',
  solutions: 'solutions',
  'about-us': 'aboutUs',
  advertise_page: 'advertise',
}
const findKeyByValue = (
  map: Record<string, string>,
  value: string
): string | null => {
  return map[value] || null
}

const lang = ['it', 'en', 'es', 'fr']
const MobileNavBar = ({ user }: { user: LoginResponse }) => {
  const userName = useSelector((state: RootState) => state.user.userName)
  const navItems = useNavItems()
  const params = useParams()
  const pathname = usePathname()
  const [currentIndex, setCurrentIndex] = useState(0)
  const screenSize = useScreenSize()
  const { t } = useTranslation()
  useEffect(() => {
    // Scroll to the initial active tab when component mounts
    const activeNav = pathname.split('/')[2]
    const indexVal = findKeyByValue(routeMap, activeNav)
    const index = items.indexOf(indexVal ?? '')
    const activeElement = document.getElementById(items[index])
    activeElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    })
  }, [pathname])

  const scrollName = (id: string) => {
    const element = document.getElementById(id)
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    })
  }

  const handleScroll = (direction: 'prev' | 'next') => {
    if (direction === 'prev' && currentIndex > 0) {
      const newIndex = currentIndex - 1
      setCurrentIndex(newIndex)
      scrollName(items[newIndex])
    } else if (direction === 'next' && currentIndex < items.length - 1) {
      const newIndex = currentIndex + 1
      setCurrentIndex(newIndex)
      scrollName(items[newIndex])
    }
  }
  const navLeft = () => {
    return (
      screenSize?.width <= 768 &&
      (lang.includes(pathname.split('/')[pathname.split('/').length - 1]) ||
        pathname.split('/')[pathname.split('/').length - 1] == 'contact-us')
    )
  }
  return (
    <div className="mobile-nav-bar">
      {/* <BaseModal /> */}

      <div className="flex mb-[-25px]  items-center w-[1280px] gap-[129px] mobile:w-[275px] mobile:gap-[10px] relative mobile:left-[100px] mobile:top-[-0px] mobile-navbar">
        {/* LOGO */}
        <div
          className={`w-[198.4px] h-[79.6px] relative top-[-0px] mobile:w-[160px] ${navLeft() ? '' : '!left-[-67px]'}`}
        >
          <Link href={`/${params?.lng}`}>
            <Image
              loading="lazy"
              width={200}
              height={72}
              src={`/assets/logo/logo_${params?.lng}.svg`}
              alt=""
            />
          </Link>
        </div>
        {/* Language-drodown */}
        <div className="flex items-center  justify-center w-20 gap-2 language-dropdown mobile:w-[3rem] mobile:mx-1">
          <LanguageDropDown lng={String(params?.lng) ?? 'en'} />
        </div>
        {/* Profile */}
        <UserProfile isMobile={true} user={user} />
      </div>
      {/* Search bar */}
      <div className="nav-search-bar">
        <Suspense>
          <HeaderSearchBar />
        </Suspense>
      </div>
      {/* Nav-categories */}
      <div className="relative">
        <div className="flex w-full justify-start nav-scroll-item mobile-width mobile:overflow-scroll mobile:ml-[10px]">
          <ul
            className={`flex cursor-pointer text-base items-center mt-4 h-[39.6px] mobile:relative pr-10 pl-10 mobile:gap-[0.8rem] ${pathname.includes('/en') ? 'gap-8' : 'gap-4'}`}
          >
            {navItems().map(
              ({ href, text, onClick, id, condition, includes }) => {
                const isActive = includes
                  ? pathname.split('/')[2] == includes
                  : false

                if (condition && !condition(userName)) return null
                return (
                  <li
                    key={id}
                    className={`${isActive || (condition && ['en', 'fr', 'it', 'es'].includes(pathname.split('/').pop() ?? 'en')) ? 'navActive font-ns_bold' : ''} top-nav-bar mobile:shadow-[0_0_6px_0_rgba(0,0,0,0.07)] mobile:px-3 mobile:py-1.5 mobile:border-b-2 mobile:border-orange`}
                    id={id}
                    onClick={onClick}
                  >
                    <Link
                      locale={false}
                      href={`/${params?.lng}${href}`}
                      prefetch={true}
                      replace
                    >
                      {t(text)}
                    </Link>
                  </li>
                )
              }
            )}
          </ul>
        </div>
        <div className="mobile:relative">
          <Image
            loading="lazy"
            width={30}
            height={30}
            src="/assets/Icons/scroll-left.svg"
            alt="scroll-left"
            className="hidden mobile:block mobile:w-[40px] mobile:h-[auto] mobile:absolute mobile:left-[-10px] mobile:top-[60px] bg-white"
            id="prevBtn"
            onClick={() => handleScroll('prev')}
          />
          <Image
            loading="lazy"
            width={30}
            height={30}
            src="/assets/Icons/scroll-right.svg"
            alt="scroll-right"
            className="hidden mobile:block mobile:w-[40px] mobile:h-[auto] mobile:absolute mobile:right-[-5px] mobile:top-[60px] bg-white"
            id="nextBtn"
            onClick={() => handleScroll('next')}
          />
        </div>
      </div>
    </div>
  )
}

export default MobileNavBar
