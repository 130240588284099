import { useDispatch } from 'react-redux'
import { setNewsPageNo } from '../globalRedux/filter/newsFilter'
import useLoginRouter from './useLoginRouter'

const useNavItems = () => {
  const dispatch = useDispatch()
  const navigateToLogin = useLoginRouter()

  const navItems = () => {
    return [
      {
        href: '/buyers_alert',
        text: 'Buyers Alert',
        includes: 'buyers_alert',
        id: 'buyersAlert',
      },
      {
        href: '/sales_offers',
        text: 'Sales Offers',
        includes: 'sales_offers',
        id: 'salesOffers',
      },
      {
        href: '/news',
        text: 'News',
        includes: 'news',
        onClick: () => dispatch(setNewsPageNo(1)),
        id: 'news',
      },
      {
        href: `/`,
        text: 'Register your Company',
        id: 'register',
        condition: (userName: string) => !userName,
        onClick: () => navigateToLogin('signup'),
      },
      {
        href: '/events',
        text: 'Events',
        includes: 'events',
        id: 'events'
      },
      {
        href: '/solutions/for_buyers',
        text: 'Solutions',
        includes: 'solutions',
        id: 'solutions',
      },
      {
        href: '/about-us',
        text: 'About Us',
        includes: 'about-us',
        id: 'aboutUs',
      },
      {
        href: '/advertise_page/website_ad',
        text: 'Advertise',
        includes: 'advertise_page',
        id: 'advertise',
      },
     
    ]
  }
  return navItems
}
export default useNavItems
