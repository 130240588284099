import {
  setLoggedIn,
  setName,
  setUser,
  setUserID,
  setUserRole,
} from '@/app/globalRedux/userStore/userStore'
import { useDispatch } from 'react-redux'
import { LoginResponse } from '../types/loginResponseType'

const useSetLoginBasedOnRole = () => {
  const dispatch = useDispatch()

  const setLoginDetails = (loginDetails: LoginResponse) => {
    if (loginDetails.status !== 'success') return
    const { user } = loginDetails
    if (!user) return
    const { id, first_name, last_name, company, email } = user
    dispatch(setLoggedIn(true))
    if (id) {
      dispatch(setUserID(id))
      dispatch(setUser(user))
      dispatch(setUserRole(user.role?.role_name))
    }
    let fullName =
      !first_name && !last_name
        ? company?.name || ''
        : `${first_name ?? ''} ${last_name ?? ''}`
    if (!fullName || fullName.length <= 0) {
      fullName = email.split('@')[0]
    }

    dispatch(setName(fullName))
  }

  return setLoginDetails
}

export default useSetLoginBasedOnRole
