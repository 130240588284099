'use client'
import { LanguageBase } from './LanguageBase'
import { usePathname } from 'next/navigation'
export const LanguageDropDown = ({ lng }: { lng: string }) => {
  const pathnameParts = usePathname().split('/')
  let pathName = usePathname()
  if (
    pathnameParts.length > 1 &&
    ['en', 'it', 'fr', 'es'].includes(pathnameParts[1])
  ) {
    pathName = pathnameParts.slice(2).join('/')
  }
  return <LanguageBase lng={lng} path={pathName} />
}
