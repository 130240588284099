'use client'

import { setLocaleLangauge } from '@/app//globalRedux/userStore/userStore'
import { setOpen } from '@/app/globalRedux/signUp/signUp'
import { AppDispatch, RootState } from '@/app/globalRedux/store'
import useLocalizedRouter from '@/app/hooks/useLocalizedRouter'
import useScreenSize from '@/app/hooks/useScreenSize'
import { useTranslation } from '@/app/i18n/client'
import { LoginResponse } from '@/app/types/loginResponseType'
import { getDomain } from '@/lib/utils'
import * as loginService from '@/services/loginService'
import {
  useParams,
  usePathname,
  useRouter,
  useSearchParams,
} from 'next/navigation'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'sonner'
import PaymentModal from '../LoginOrSignUpModal/(paymentStatus)/PaymentModal'
import DeskTopNavBar from './DeskTopNavBar'
import MobileNavBar from './MobileNavBar'

const Navbar = ({ user }: { user: LoginResponse }) => {
  const { t } = useTranslation()

  const params = useParams()
  const dispatch: AppDispatch = useDispatch()
  const pushWithLocale = useLocalizedRouter()
  const serchParams = useSearchParams()
  const router = useRouter()
  const pathName = usePathname()
  const token = serchParams.get('login_confirmation_token')
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn)
  const screenSize = useScreenSize()
  useEffect(() => {
    if (loggedIn) {
      router.push(pathName)
    }
  }, [loggedIn])

  useEffect(() => {
    const validateAccountWithToken = async () => {
      const response = await loginService.validateEmail(token || '')
      if (response.payment_info && response.payment_info.url?.length) {
        window.location.href = response.payment_info.url
      }
      if (response?.plan) {
        let message = 'Please Login to Continue'
        switch (response?.plan) {
          case 'Free':
            message = 'Currently you are on free plan. Please Login to Continue'
            break
          case 'Gold':
            message = 'Currently you are on Gold plan. Please Login to Continue'
            break
          case 'Silver':
            message =
              'Currently you are on Silver plan. Please Login to Continue'
            break
          case 'Platinum':
            message =
              'Currently you are on Platinum plan. Please Login to Continue'
            break
        }
        toast.success(t(message))
        dispatch(setOpen(true))
        pushWithLocale('?page=login')
      }
    }

    if (token) {
      validateAccountWithToken()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(setLocaleLangauge(params?.lng ?? 'en'))
    document.cookie = `lang' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${getDomain()}`
    document.cookie = `lang=${params?.lng};path=/;SameSite=Lax;domain=${getDomain()};secure`
  }, [params?.lng])

  // nav scroll

  return (
    <div
      className="flex w-full pt-10 pb-3 bg-orange_tag items-center justify-center mobile:mb-[100px] mobile:pt-[2rem]"
      suppressHydrationWarning
    >
      <PaymentModal />
      {screenSize.width <= 768 ? (
        <MobileNavBar user={user} />
      ) : (
        <DeskTopNavBar user={user} />
      )}
    </div>
  )
}

export default Navbar
