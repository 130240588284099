import { logOutUser } from '@/services/loginService'
import { useDispatch } from 'react-redux'
import { toast } from 'sonner'
import { useTranslation } from '../i18n/client'

const useLogout = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const logout = async () => {
    await logOutUser()
    await dispatch({ type: 'logout/LOGOUT' })
    // WARNING: Wait for the logout request to complete and fetch updated data from the server.
    // This ensures that the session cookie is correctly invalidated, preventing the
    // same session from persisting on subsequent requests.
    if (process.env.NODE_ENV === 'development') {
      window.location.href = `${process.env.API_LOCALHOST_ORIGIN_URL}/auth/logout`
    } else {
      window.location.href = window.location.origin + '/auth/logout'
    }
    toast.success(t('User Logged Out'))
  }

  return logout
}

export default useLogout
